
import { Options, Vue } from "vue-class-component"
import { Invite } from "@/models"

export type InviteStatus = "COMPLETED" | "TERMINATED" | "OVER_QUOTA"

@Options({
  props: {
    invite: {
      type: Object,
      default: {
        isInvited: false,
        isSurveyAvailable: false,
        incentive: 195,
        surveyLink: "",
        surveyLength: 45,
        topic: "",
        status: "TERMINATED"
      }
    },
    onCompletionMobileOnly: {
      type: Boolean,
      default: true
    },
    onCompletionShow: {
      type: Boolean,
      default: true
    },
    images: {
      type: Object,
      default: () => ({
        incentive: {
          regular: "incentive",
          mobile: "incentive"
        },
        clock: {
          regular: "clock",
          mobile: "clock"
        },
        online: {
          regular: "devices",
          mobile: "devices"
        }
      })
    }
  }
})
export default class InviteBlockV2 extends Vue {
  invite: Invite
  onCompletionMobileOnly: boolean

  get getIncentiveText() {
    return this.invite.isUnpaid ? "Unpaid" : `Pays $${this.invite.incentive}`
  }
}
