
import { Options, Vue } from "vue-class-component";
import InviteBlockV2 from "@/components/InviteBlock/InviteBlockV2.vue";
import CustomModal from "@/components/Modal/CustomModal.vue";
import { $vfm } from 'vue-final-modal';


@Options({
  components: {
    InviteBlockV2,
    CustomModal
  },
})
export default class SurveyInfo extends Vue {
  images = {
    incentive: {
      regular: "incentive",
      mobile: "incentive",
    },
    clock: {
      regular: "clock",
      mobile: "clock",

    },
    online: {
      regular: "online",
      mobile: "online",
    }
  }
  eligibleModalName = "survey-info-modal"

  onStartScreener() {
    this.$router.push({
      name: "SurveyDisclaimerV2"
    })
  }

  onLinkClick(): void {
    $vfm.show(this.eligibleModalName)
  }

  hideEligibleModal(): void {
    $vfm.hide(this.eligibleModalName)
  }
}
